import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { Spin, Card, PageHeader, Result, Typography } from "antd";

// style import
import "./stats.scss";

// function imports
import { fetchStats } from "./statCalls";

// component destructs
const { Title } = Typography;

function Stats() {
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [statsData, setStatsData] = useState({});

  const renderAllStats = (statsObj) => {
    return Object.keys(statsObj).map(function (key, index) {
      return renderStatCard(key, statsObj[key]);
    });
  };

  const renderStatCard = (titleSlug, value) => {
    let title;
    switch (titleSlug) {
      case "noOfBooks":
        title = "Your Books";
        break;
      case "noOfInvoices":
        title = "Your Invoices";
        break;
      case "noOfAgreements":
        title = "Your Agreements";
        break;
      case "noOfPackages":
        title = "Packages Purchased";
        break;

      default:
        title = titleSlug;
        break;
    }
    return (
      <Card
        size="small"
        title={title}
        className="stat-card"
        extra={<Button type="primary">Open</Button>}
      >
        <Title level={3}>{value}</Title>
      </Card>
    );
  };

  useEffect(() => {
    async function fetchProfileData() {
      try {
        setLoading(true);
        setError(null);
        setStatsData({});
        const response = await fetchStats();
        const { data, error, message } = response;
        if (!error) {
          setStatsData(data);
        } else {
          setError(message || "Error while fetching data");
        }
      } catch (error) {
        console.error(error);
        setError("Error while fetching data.");
      } finally {
        setLoading(false);
      }
    }
    fetchProfileData();
  }, []);

  console.log("statsData", statsData);

  return (
    <div className="stats-wrapper">
      {isLoading ? (
        <div className="loader">
          <Spin />
        </div>
      ) : error !== null ? (
        <Result
          status="error"
          title={error}
          subTitle="please try again later."
        />
      ) : Object.keys(statsData).length === 0 &&
        statsData.constructor === Object ? (
        <Result
          status="404"
          title="404"
          subTitle="No data available, please contact support"
        />
      ) : (
        [
          <div className="header">
            <PageHeader
              className="site-page-header"
              title={` 🙏 ${statsData.fullName}`}
            />
          </div>,
          <div className="body">
            {renderAllStats({
              noOfBooks: statsData.noOfBooks,
              noOfInvoices: statsData.noOfInvoices,
              noOfAgreements: statsData.noOfAgreements,
              noOfPackages: statsData.noOfPackages,
            })}
            {
              <Card size="small" title="Book Sales Data" className="stat-card">
                <p>
                  Currently Sales data is not available, please contact your
                  publishing manager for more info.
                </p>
              </Card>
            }
          </div>,
        ]
      )}
    </div>
  );
}

export default Stats;
