import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useHistory, useRouteMatch, Link } from "react-router-dom";
import {
  Spin,
  Card,
  PageHeader,
  Result,
  Typography,
  Carousel,
  Button,
  Upload,
  Alert,
  Divider,
  message,
  Badge,
} from "antd";

import {
  PieChartOutlined,
  DesktopOutlined,
  ContainerOutlined,
  UploadOutlined,
  FileTextOutlined,
  PhoneOutlined,
  MailOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import "./manager.scss";

//asset imports
import logo from "../../asset/images/logo.svg";

// function imports
import { eraseCookie } from "../../functions/cookie";
import { fetchManagerDetails } from "./managerCalls";

// destructors
// const { SubMenu } = Menu;
const { Title, Text } = Typography;

function Manager() {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [managerData, setManagerData] = useState({});

  useEffect(() => {
    async function fetchDocuments() {
      try {
        setLoading(true);
        setError(null);
        const response = await fetchManagerDetails();
        const { data, error, message } = response;
        if (!error) {
          setManagerData(data ? data : {});
        } else {
          setError(message || "Error while fetching data");
        }
      } catch (error) {
        console.error(error);
        setError("sorry, something went wrong, please try again.");
      } finally {
        setLoading(false);
      }
    }
    fetchDocuments();
  }, []);

  // if (Object.keys(managerData).length === 0) {
  //   return (
  //     <center className="box no-book">
  //       <br />
  //       <Alert message="Manager is not assigned yet!" type="warning" />
  //       <br />
  //       <Text></Text>
  //       <br />
  //       <Text>Don't worry, we will assign publishing manager to you soon</Text>
  //       <br />
  //     </center>
  //   );
  // }

  return (
    <div className="manager-wrapper">
      {isLoading ? (
        <div className="loader">
          <Spin />
        </div>
      ) : error !== null ? (
        <Result
          status="error"
          title={error}
          subTitle="please try again later."
        />
      ) : Object.keys(managerData).length === 0 ? (
        <center className="box no-book">
          <br />
          <Alert message="Manager is not assigned yet!" type="warning" />
          <br />
          <Text></Text>
          <br />
          <Text>
            Don't worry, we will assign publishing manager to you soon
          </Text>
          <br />
        </center>
      ) : (
        [
          <div className="header">
            <PageHeader
              className="site-page-header"
              title={`Assigned Manager`}
              subTitle="Assigned publishing manager will help you throughout the entire publishing journey."
            />
          </div>,
          //   <div className="body">{renderBooksList(documentsData)}</div>,
          <div className="body ">
            <center className="">
              <p>
                {managerData.title} {managerData.firstName}{" "}
                {managerData.lastName} is your publishing manager, please reach
                out via any channel for any queries or communication.
              </p>
              <Divider />
              <h1>{`${managerData.title} ${managerData.firstName} ${managerData.lastName}`}</h1>
              <h4>{managerData.profession}</h4>
              <br />
              <br />
              <div className="contact-details">
                <div className="one-info box">
                  <div className="title">
                    <MailOutlined />
                    Email
                  </div>
                  <Text copyable className="info">
                    publish@wisdomwords.in
                  </Text>
                  <div className="comment">
                    Please prefer email as a primary channel of communication as
                    it's easy to forward messages with other team members via
                    email.
                  </div>
                </div>
                <div className="one-info box">
                  <div className="title">
                    <PhoneOutlined />
                    Contact Number
                  </div>
                  <Text copyable className="info">
                    {managerData.phoneNumber}
                  </Text>
                  <div className="comment">
                    Call between 11:00AM to 6:00PM. (Before calling, please
                    confirm timings with your publishing manager)
                  </div>
                </div>
                <div className="one-info box">
                  <div className="title">
                    <WhatsAppOutlined />
                    Whatsapp Number
                  </div>
                  <Text copyable className="info">
                    {managerData.whatsappNumber}
                  </Text>
                  <div className="comment">
                    Drop a Whatsapp message as a reminder.
                  </div>
                </div>
              </div>
            </center>
          </div>,
        ]
      )}
    </div>
  );
}

{
  /* <MailOutlined /> */
}

export default Manager;
