import React, { useState, useEffect } from "react";
import {
  Spin,
  Typography,
  Avatar,
  Card,
  Result,
  Divider,
  Space,
  message,
} from "antd";
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Switch,
  Checkbox,
  Alert,
} from "antd";
import penIcon from "../../asset/images/pen.svg";
import { UserOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { titleOptions } from "../../functions/static-data";

// style import
import "./profile.scss";

// function imports
import { fetchProfile, saveProfile } from "./profileCalls";

//destructs
const { Title, Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;
function Profile() {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [profileData, setProfileData] = useState({});
  const [didFormChange, setDidFormChange] = useState(false);
  useEffect(() => {
    async function fetchProfileData() {
      try {
        setLoading(true);
        setError(null);
        const response = await fetchProfile();
        const { data, error, message } = response;
        if (!error) {
          setProfileData(data);
        } else {
          setError(message || "Error while fetching data");
        }
      } catch (error) {
        console.error(error);
        setError("sorry, something went wrong, please try again.");
      } finally {
        setLoading(false);
      }
    }
    fetchProfileData();
  }, []);

  const createAddressLine = (addressObj) => {
    if (addressObj) {
      return "N/A";
    } else {
      const { address = null, city, state, pincode, country } = addressObj;
      const addressLine = `
      ${address}
      ${city} - ${state} : ${pincode}
      ${country}
      `;
      return addressLine;
    }
  };

  const onFormValuesChange = () => {
    setDidFormChange(true);
  };

  const copyBillingToShipping = () => {
    const { b_address, b_city, b_state, b_pincode } = form.getFieldsValue([
      "b_address",
      "b_city",
      "b_state",
      "b_pincode",
    ]);

    form.setFieldsValue({
      s_address: b_address,
      s_city: b_city,
      s_pincode: b_pincode,
      s_state: b_state,
    });
  };

  const onProfileSave = (values) => {
    const {
      b_state,
      b_city,
      b_pincode,
      b_address,
      s_state,
      s_city,
      s_pincode,
      s_address,
    } = values;

    values.billingAddress = {
      address: b_address,
      city: b_city,
      state: b_state,
      pincode: b_pincode,
    };
    values.shippingAddress = {
      address: s_address,
      city: s_city,
      state: s_state,
      pincode: s_pincode,
    };
    setIsSaving(true);
    saveProfile(values)
      .then((data) => {
        console.log("got data:", data.data);
        message.success("Successfully Saved");

        setProfileData(data);
      })
      .catch((error) => {
        message.error("unable to save data");
      })
      .finally(() => {
        setIsSaving(false);
        setDidFormChange(false);
      });
  };

  const {
    title,
    firstName,
    middleName,
    lastName,
    penName,
    profession,
    email,
    emailVerified,
    phoneNumber,
    whatsappNumber,
    billingAddress = {},
    shippingAddress = {},
  } = profileData;

  const fullName = [title, firstName, middleName, lastName]
    .filter(Boolean)
    .join(" ");

  const {
    address: b_address,
    city: b_city,
    state: b_state,
    pincode: b_pincode,
  } = billingAddress;

  const {
    address: s_address,
    city: s_city,
    state: s_state,
    pincode: s_pincode,
  } = shippingAddress;

  const initialValues = {
    title,
    firstName,
    middleName,
    lastName,
    penName,
    profession,
    email,
    emailVerified,
    phoneNumber,
    whatsappNumber,
    b_address,
    b_city,
    b_state,
    b_pincode,
    s_address,
    s_city,
    s_state,
    s_pincode,
  };

  return (
    <div className="profile-wrapper">
      {isLoading ? (
        <div className="loader">
          <Spin />
        </div>
      ) : error !== null ? (
        <Result
          status="error"
          title={error}
          subTitle="please try again later."
        />
      ) : Object.keys(profileData).length === 0 &&
        profileData.constructor === Object ? (
        <Result
          status="404"
          title="404"
          subTitle="No data available, please contact support"
        />
      ) : (
        <>
          <div className="profile-header">
            <Space>
              <Title level={4}>Your Profile</Title>
              {didFormChange ? (
                <Alert
                  message="Profile changes are not saved yet, don't forget to save"
                  type="warning"
                />
              ) : null}
            </Space>
          </div>
          <div className="profile-body">
            <div className="profile-sidebar">
              <img src={penIcon} alt="profile" />
              <Text>{fullName}</Text>
            </div>
            <fieldset disabled={isSaving} className="profile-main">
              <Form
                colon={false}
                initialValues={initialValues}
                form={form}
                onValuesChange={onFormValuesChange}
                onFinish={onProfileSave}
              >
                <Form.Item className="right">
                  <Button
                    htmlType="submit"
                    type="primary"
                    loading={isSaving}
                    disabled={!didFormChange}
                  >
                    Save All Details
                  </Button>
                </Form.Item>
                <Divider>
                  <Text className="section-title" type="secondary">
                    General
                  </Text>
                </Divider>

                <div className="flex row gap20">
                  <Form.Item
                    name="title"
                    rules={[
                      {
                        required: true,
                        message: "Please input your title.",
                      },
                    ]}
                    label="Title"
                  >
                    <Select placeholder="Select title">
                      {titleOptions.map((option, index) => (
                        <Option key={index} value={option}>
                          {option}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your title.",
                      },
                    ]}
                    label="First Name"
                  >
                    <Input placeholder="input placeholder" />
                  </Form.Item>
                  <Form.Item
                    name="middleName"
                    rules={[
                      {
                        required: false,
                        message: "Please input your title.",
                      },
                    ]}
                    label="Middle Name"
                  >
                    <Input placeholder="input placeholder" />
                  </Form.Item>
                  <Form.Item
                    name="lastName"
                    rules={[
                      {
                        required: false,
                        message: "Please input your title.",
                      },
                    ]}
                    label="Last Name"
                  >
                    <Input placeholder="input placeholder" />
                  </Form.Item>
                </div>
                <div className="flex row gap20">
                  <Form.Item name="penName" label="Pen Name (if any)">
                    <Input placeholder="pen Name" />
                  </Form.Item>
                  <Form.Item name="profession" label="Profession">
                    <Input placeholder="Profession" />
                  </Form.Item>
                </div>

                <Divider>
                  <Text className="section-title" type="secondary">
                    Contact
                  </Text>
                </Divider>

                <div className="flex row gap20">
                  <Form.Item name="email" label="Email Address">
                    <Input placeholder="Email Address" />
                  </Form.Item>
                  <Form.Item name="phoneNumber" label="Phone Number">
                    <Input placeholder="Phone Number" />
                  </Form.Item>
                  <Form.Item name="whatsappNumber" label="Whatsapp Number">
                    <Input placeholder="Whatsapp Number" />
                  </Form.Item>
                </div>
                <Divider>
                  <Text className="section-title" type="secondary">
                    Address
                  </Text>
                </Divider>
                <div className="flex row space-evenly">
                  <div className="billing-address flex column">
                    <center>
                      <Title level={5}>Billing Address</Title>
                      <Text size="small">
                        The invoices will include the following address.
                      </Text>
                    </center>
                    <Form.Item name="b_state" label="State">
                      <Input placeholder="State" name="state" />
                    </Form.Item>
                    <Form.Item name="b_city" label="City">
                      <Input placeholder="City" name="city" />
                    </Form.Item>
                    <Form.Item name="b_pincode" label="Pincode" type="number">
                      <Input placeholder="Pincode" name="pincode" />
                    </Form.Item>
                    <Form.Item name="b_address" label="Address">
                      <TextArea placeholder="Address" name="address" />
                    </Form.Item>
                  </div>
                  <div className="shipping-address flex column">
                    <center>
                      <Title level={5}>Shipping Address</Title>
                      <Button
                        onClick={copyBillingToShipping}
                        icon={<ArrowDownOutlined />}
                        size="small"
                      >
                        Copy Billing Address
                      </Button>
                    </center>

                    <Form.Item name="s_state" label="State">
                      <Input placeholder="State" name="state" />
                    </Form.Item>
                    <Form.Item name="s_city" label="City">
                      <Input placeholder="City" name="city" />
                    </Form.Item>
                    <Form.Item name="s_pincode" label="Pincode" type="number">
                      <Input placeholder="Pincode" name="pincode" />
                    </Form.Item>
                    <Form.Item name="s_address" label="Address">
                      <TextArea placeholder="Address" name="address" />
                    </Form.Item>
                  </div>
                </div>
                <Divider />

                <Form.Item className="right">
                  <Button
                    htmlType="submit"
                    type="primary"
                    loading={isSaving}
                    disabled={!didFormChange}
                  >
                    Save All Details
                  </Button>
                </Form.Item>
              </Form>
            </fieldset>
          </div>
        </>
      )}
    </div>
  );
}

export default Profile;
