import API from "../../functions/axios";

export function getBooksList() {
  return API.get("get-books-list");
}

export function saveBookFromAuthor(data) {
  return API.post("save-book", {
    ...data,
  });
}
