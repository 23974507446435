import React, { useState, useEffect } from "react";
import {
  Spin,
  Typography,
  Avatar,
  Card,
  Result,
  Divider,
  Space,
  PageHeader,
  message,
} from "antd";
import { useHistory, Link } from "react-router-dom";

import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Switch,
  Checkbox,
  Alert,
} from "antd";
import penIcon from "../../asset/images/pen.svg";
import {
  UserOutlined,
  ArrowDownOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import { titleOptions } from "../../functions/static-data";

// style import
import "./books.scss";

// function imports
import { saveBookFromAuthor } from "./booksCalls";

//destructs
const { Title, Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;
function Profile() {
  const history = useHistory();
  const [form] = Form.useForm();

  const [bookOwner, setBookOwner] = useState("Author");
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [didFormChange, setDidFormChange] = useState(false);

  const saveBookOwnerType = (e) => {
    setBookOwner(e.target.value);
  };

  // async function saveBookData(formData) {
  //   console.log("saveBookData", formData);

  //   try {
  //     setIsSaving(true);
  //     const response = await saveBookFromAuthor(formData);
  //     const { error, message } = response;
  //     if (!error) {
  //       setSuccess(true);
  //     } else {
  //       // setError(message || "Error while fetching data");
  //     }
  //   } catch (error) {
  //     //   setError("sorry, something went wrong, please try again.");
  //   } finally {
  //     setIsSaving(false);
  //   }
  // }

  const onBookSave = (values) => {
    console.log("onbooksave", values);
    saveBookFromAuthor(values)
      .then((data) => {
        console.log("dataaa", data);
        const { error, message: msg } = data;
        if (error) {
          message.error(msg);
          throw new Error(error);
        }
        message.success(msg);
        history.push("/books");
      })
      .catch((error) => {
        console.log(error);
        message.error(
          "Something went wrong, please contact your manager to resolve this."
        );
      });
  };

  const onFormValuesChange = () => {
    setDidFormChange(true);
  };

  const languages = [
    "English",
    "Hindi",
    "Urdu",
    "Assamese",
    "Bengali",
    "Bodo",
    "Dogri",
    "Gujarati",
    "Kannada",
    "Kashmiri",
    "Konkani",
    "Maithili",
    "Malayalam",
    "Manipuri",
    "Marathi",
    "Nepali",
    "Oriya",
    "Punjabi",
    "Sanskrit",
    "Santhali",
    "Sindhi",
    "Tamil",
    "Telugu",
  ];

  return (
    <div className="add-book-wrapper">
      {error !== null ? (
        <Result
          status="error"
          title={error}
          subTitle="please try again later."
        />
      ) : Object.keys({}).length !== 0 && {}.constructor === Object ? (
        <Result
          status="404"
          title="404"
          subTitle="No data available, please contact support"
        />
      ) : (
        <>
          <div className="add-book-header">
            <PageHeader
              className="site-page-header"
              title={
                <>
                  <Title level={4}>Add new book</Title>
                  <Link to={`/books`}>
                    <Button type="primary" size="small">
                      <LeftOutlined />
                      View all books
                    </Button>
                  </Link>
                </>
              }
              // subTitle={'add'}
            />
          </div>
          <Card className="add-book-body">
            <fieldset disabled={isSaving}>
              <Form
                colon={false}
                // initialValues={initialValues}
                form={form}
                onValuesChange={onFormValuesChange}
                onFinish={onBookSave}
              >
                <Form.Item className="right">
                  <Button
                    htmlType="submit"
                    type="primary"
                    loading={isSaving}
                    disabled={!didFormChange}
                  >
                    Save Details
                  </Button>
                </Form.Item>
                <Divider />
                <center>
                  <Radio.Group onChange={saveBookOwnerType} value={bookOwner}>
                    <Radio value="Author">I am Author</Radio>
                    <Radio value="Compiler">I am Compiler</Radio>
                  </Radio.Group>
                </center>
                <Divider />

                <div className="flex column gap20">
                  <Form.Item
                    name={bookOwner === "Author" ? "authors" : "compilers"}
                    rules={[
                      {
                        required: true,
                        message: "Please enter name",
                      },
                    ]}
                    label={`${bookOwner}'s Name (You can add more than one)`}
                  >
                    <Select mode="tags" style={{ width: "100%" }}></Select>
                  </Form.Item>
                  <Form.Item
                    name="title"
                    rules={[
                      {
                        required: true,
                        message: "Please enter book title.",
                      },
                    ]}
                    label="Title of Book"
                  >
                    <Input placeholder="book title" />
                  </Form.Item>
                  <Form.Item
                    name="subTitle"
                    label="Sub-title of Book (Optional)"
                  >
                    <Input placeholder="Sub-title of book" />
                  </Form.Item>
                </div>
                <p></p>

                <Form.Item className="right">
                  <Button
                    htmlType="submit"
                    type="primary"
                    loading={isSaving}
                    disabled={!didFormChange}
                  >
                    Save Details
                  </Button>
                </Form.Item>
                <Divider />
                <ul>
                  <li>
                    Other relevant book metadata such as, "About the book",
                    "Language", "Preface", "Binding" etc will be added by the
                    publishing manager after discussing with you.
                  </li>
                </ul>
              </Form>
            </fieldset>
          </Card>
        </>
      )}
    </div>
  );
}

export default Profile;
