import React, { useEffect } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

// style import
import "./App.scss";
import "antd/dist/antd.css";

// asset import
// import logo from "./asset/images/logo.svg";

// function imports
import { getCookie, eraseCookie } from "./functions/cookie";

// component import
import LoginPage from "./pages/login/login";
import Homepage from "./pages/homepage/homepage";
import VerifyEmail from "./pages/verifyemail/verifyemail";

function App() {
  let history = useHistory();
  let location = useLocation();
  useEffect(() => {
    if (location.pathname !== "verifyemail" && getCookie("token") === null) {
      history.push("/login");
    }
  }, [history]);

  const logout = () => {
    eraseCookie("token");
    history.push("/login");
  };

  return (
    <div className="App">
      <Switch>
        <Route path="/verifyemail">
          <VerifyEmail />
        </Route>
        <Route path="/login" exact>
          <LoginPage />
        </Route>
        <Route path="/">
          <Homepage />
        </Route>

        {/* <Route
          path="/login"
          exact
          render={(routerProps) => (
            <Sample {...routerProps} sampleProp={"sample"} />
          )}
        />
        <Route path="/something" component={Something} />
        <Route path="/default" render={() => <Redirect to="/" />} />
        <Route component={NoMatch} /> */}
      </Switch>

      {/* <button onClick={logout}>Logout</button> */}
    </div>
  );
}

export default App;
