import React, { useState, useEffect, useRef } from "react";

import { useHistory, useRouteMatch, Link, useParams } from "react-router-dom";
import {
  Spin,
  Card,
  PageHeader,
  Result,
  Typography,
  Carousel,
  Button,
  Icon,
  Collapse,
  Steps,
  Divider,
  Alert,
} from "antd";

import {
  PieChartOutlined,
  DesktopOutlined,
  ContainerOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import "./support.scss";

//asset imports
import logo from "../../asset/images/logo.svg";

// function imports
import { eraseCookie } from "../../functions/cookie";
// import { getOneBook } from "./onebookCalls";
const { Title, Text } = Typography;
const { Panel } = Collapse;
const { Step } = Steps;

// destructors
// const { SubMenu } = Menu;

function OneBook() {
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSent, setIsSent] = useState(false);

  return (
    <div className="support-wrapper">
      <PageHeader
        className="site-page-header"
        title={<Text>Support</Text>}
        subTitle={""}
      />
      <Divider />

      <div className="support-body">
        {isSent ? (
          <div className="sent-body box">
            <br />
            <Alert message="Message sent to Manager" type="success" />
            <br />
            <br />
            <Text>your message has been successfully sent to manager</Text>
            <br />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default OneBook;
