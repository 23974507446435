import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useHistory, useRouteMatch, Link } from "react-router-dom";
import {
  Spin,
  Card,
  PageHeader,
  Result,
  Typography,
  Carousel,
  Button,
  Upload,
  Alert,
  Divider,
  message,
  Badge,
} from "antd";

import {
  PieChartOutlined,
  DesktopOutlined,
  ContainerOutlined,
  UploadOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import "./documents.scss";

//asset imports
import logo from "../../asset/images/logo.svg";

// function imports
import { eraseCookie } from "../../functions/cookie";
import { getUserDocuments } from "./documentsCalls";

// destructors
// const { SubMenu } = Menu;
const { Title, Text } = Typography;

function Documents() {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [documentsData, setDocumentsData] = useState([]);

  useEffect(() => {
    async function fetchDocuments() {
      try {
        setLoading(true);
        setError(null);
        const response = await getUserDocuments();
        const { data, error, message } = response;
        if (!error) {
          setDocumentsData(data);
        } else {
          setError(message || "Error while fetching data");
        }
      } catch (error) {
        console.error(error);
        setError("sorry, something went wrong, please try again.");
      } finally {
        setLoading(false);
      }
    }
    fetchDocuments();
  }, []);

  const renderBooksList = (documentsData) => {
    return documentsData.map((bookData) => {
      const { images, title, subTitle, id } = bookData;
      return (
        <div className="one-book-block">
          <div className="book-images">
            <Carousel autoplay>
              {images.map((image) => {
                return (
                  <div className="book-image">
                    <img src={image} alt="" />
                  </div>
                );
              })}
            </Carousel>
          </div>
          <div className="book-title">{title}</div>
          <div className="book-sub-title">{subTitle}</div>
          <center className="open-button">
            <Link to={`/book/${id}`}>
              <Button type="primary">Open</Button>
            </Link>
          </center>
        </div>
      );
    });
  };

  // if (!documentsData.length) {
  //   return (

  //   );
  // }

  return (
    <div className="documents-wrapper">
      {isLoading ? (
        <div className="loader">
          <Spin />
        </div>
      ) : error !== null ? (
        <Result
          status="error"
          title={error}
          subTitle="please try again later."
        />
      ) : !documentsData.length ? (
        <center className="box no-book">
          <br />
          <Alert message="There is no document found." type="error" />
          <br />
          <Text></Text>
          <br />
          <Text>All the book publishing related documents will be shown here.</Text>
          <br />
        </center>
      ) : (
        [
          <div className="header">
            <PageHeader
              className="site-page-header"
              title={`Documents`}
              subTitle="All the book publishing related documents are here."
            />
          </div>,
          //   <div className="body">{renderBooksList(documentsData)}</div>,
          <div className="body">
            {documentsData.map((document) => {
              const { title, url, comment, updatedAt, type } = document;
              return (
                <div className="box">
                  <div className={`badge ${type}`}>{type}</div>
                  <Text type="secondary">
                    {dayjs(updatedAt).format("DD MMM, YY")}
                  </Text>
                  <Title level={5}>{title}</Title>
                  <Text></Text>

                  <a href={url} target="_blank">
                    <Button icon={<FileTextOutlined />}>Open</Button>
                  </a>
                  <br />
                  <pre>{comment}</pre>
                </div>
              );
            })}
            <Divider></Divider>
          </div>,
        ]
      )}
    </div>
  );
}

export default Documents;
