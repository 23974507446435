import React, { useState, useEffect, useRef } from "react";

import { useHistory, useRouteMatch, Link, useParams } from "react-router-dom";
import {
  Spin,
  Card,
  PageHeader,
  Result,
  Typography,
  Carousel,
  Button,
  Icon,
  Collapse,
} from "antd";

import {
  PieChartOutlined,
  DesktopOutlined,
  ContainerOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import "./onebook.scss";

import { getBookSteps } from "./onebookCalls";
//asset imports
const { Panel } = Collapse;

// function imports

function OneBookSteps({ bookId }) {
  console.log("------", bookId);
  const carouselRef = useRef();
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stepsData, setStepsData] = useState({});

  useEffect(() => {
    async function fetchStepsData() {
      try {
        setLoading(true);
        setError(null);
        const response = await getBookSteps(bookId);
        const { data, error, message } = response;
        if (!error) {
          setStepsData(data);
        } else {
          setError(message || "Error while fetching data");
        }
      } catch (error) {
        console.error(error);
        setError("sorry, something went wrong, please try again.");
      } finally {
        setLoading(false);
      }
    }
    fetchStepsData();
  }, []);

  return (
    <div className="one-book-wrapper">
      {isLoading ? (
        <div className="loader">
          <Spin />
        </div>
      ) : error !== null ? (
        <Result
          status="error"
          title={error}
          subTitle="please try again later."
        />
      ) : stepsData.length === 0 && stepsData.constructor === Array ? (
        <Result
          status="404"
          title="404"
          subTitle="No steps available, please contact support"
        />
      ) : (
        [
          <div className="header">
            <PageHeader
              className="site-page-header"
              title="Publishing Steps "
            />
          </div>,
          <div className="steps-body">
            {stepsData.map((steps) => {
              const { stepGroupName, stepsInOrder } = steps;
              return (
                <div className="steps-group">
                  <h3>
                    {stepGroupName === "start"
                      ? "General Steps"
                      : stepGroupName + " Steps"}
                    :
                  </h3>
                  {stepsInOrder.map((step) => {
                    const { name, status } = step;
                    return (
                      <div className="single-step">
                        <div className="step-name">{name}</div>
                        <div className={`step-status ${status}`}>{status}</div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>,
        ]
      )}
    </div>
  );
}

export default OneBookSteps;
