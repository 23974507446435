import axios from "axios";
import { getCookie } from "../functions/cookie";
/*
 * * KEY FEATURES:
 * * Make XMLHttpRequests from the browser
 * * Make http requests from node.js
 * * Supports the Promise API
 * * Intercept request and response
 * * Transform request and response data
 * * Cancel requests
 * * Automatic transforms for JSON data
 * * Client side support for protecting against XSRF
 */

//* Initializes axios with a default config
const API = axios.create({
  baseURL: "https://v2.wisdomwords.in/api/",
  // baseURL: "http://localhost:3000/api/",
  timeout: 20000,
});

API.interceptors.response.use(
  // eslint-disable-next-line consistent-return
  (response) => {
    //* Unwrap axios response
    return response.data;
  },
  (error) => {
    // Do something with response error
    return Promise.reject(error);
  }
);

API.interceptors.request.use(function (config) {
  if (getCookie("token") !== null) {
    const token = getCookie("token");
    config.headers.Authorization = token;
  }
  return config;
});

export default API;
