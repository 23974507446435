// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyB6JTnRZba-6YTiI3uDLpdIO43ZB07KjfY",
  authDomain: "wwpubauth.firebaseapp.com",
  projectId: "wwpubauth",
  storageBucket: "wwpubauth.appspot.com",
  messagingSenderId: "581772324857",
  appId: "1:581772324857:web:3c537a61af39546b66d728",
  measurementId: "G-15KC8MD8BN",
};
