import API from "../../functions/axios";

export function fetchProfile() {
  return API.get("get-user-details");
}
export function saveProfile(data) {
  return API.post("save-user-details", {
    ...data,
  });
}
