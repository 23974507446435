import React from "react";
import { useHistory } from "react-router-dom";
import { Menu } from "antd";
import {
  PieChartOutlined,
  DesktopOutlined,
  ContainerOutlined,
  FolderOpenOutlined,
  UploadOutlined,
  BookOutlined,
  IdcardOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import "./menu.scss";

//asset imports
import logo from "../../asset/images/logo.svg";

// function imports
import { eraseCookie } from "../../functions/cookie";

// destructors
// const { SubMenu } = Menu;

function Sidebar({ closeSidebar }) {
  let history = useHistory();

  const logout = () => {
    eraseCookie("token");
    eraseCookie("email");
    eraseCookie("isEmailVerified");
    history.push("/login");
  };
  const onMenuClick = ({ key }) => {
    if (key === "logout") {
      logout();
      return;
    }
    closeSidebar();
    history.push(key);
  };
  return (
    <div className="menu-wrapper">
      <div className="menu-logo">
        <img src={logo} alt="" />
      </div>
      <Menu
        defaultSelectedKeys={["books"]}
        mode="inline"
        onClick={onMenuClick}
        //  theme="dark"
      >
        {/* <Menu.Item key="/homepage" icon={<PieChartOutlined />}>
          Homepage
        </Menu.Item> */}
        <Menu.Item key="/books" icon={<BookOutlined />}>
          Books
        </Menu.Item>
        <Menu.Item key="/profile" icon={<UserOutlined />}>
          Profile
        </Menu.Item>
        <Menu.Item key="/documents" icon={<FolderOpenOutlined />}>
          Documents
        </Menu.Item>
        <Menu.Item key="/upload" icon={<UploadOutlined />}>
          Upload File
        </Menu.Item>
        <Menu.Item key="/manager" icon={<IdcardOutlined />}>
          Manager
        </Menu.Item>
        {/* <Menu.Item key="/agreements" icon={<ContainerOutlined />}>
          Packages
        </Menu.Item>
        <Menu.Item key="/support" icon={<ContainerOutlined />}>
          Support
        </Menu.Item> */}
        {/* <SubMenu key="sub2" icon={<AppstoreOutlined />} title="Navigation Two">
          <Menu.Item key="9">Option 9</Menu.Item>
          <Menu.Item key="10">Option 10</Menu.Item>
          <SubMenu key="sub3" title="Submenu">
            <Menu.Item key="11">Option 11</Menu.Item>
            <Menu.Item key="12">Option 12</Menu.Item>
          </SubMenu>
        </SubMenu> */}
        <Menu.Item key="logout" onClick={logout} icon={<LogoutOutlined />}>
          Logout
        </Menu.Item>
      </Menu>
    </div>
  );
}

export default Sidebar;
