import React, { useEffect, useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";

import { Route, Switch, useRouteMatch, useHistory } from "react-router-dom";
import { getCookie, eraseCookie } from "../../functions/cookie";
import { Alert, Button, message, Spin, Typography, Result } from "antd";
// style import
import "./verifyemail.scss";

//components import

import { firebaseConfig } from "../../functions/configs";

const { Text } = Typography;
function VerifyEmail() {
  let history = useHistory();

  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    !firebase.apps.length
      ? firebase.initializeApp(firebaseConfig)
      : firebase.app();

    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      var email = getCookie("email");
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt("Please provide your email for confirmation");
      }
      // The client SDK will parse the code from the link for you.
      firebase
        .auth()
        .signInWithEmailLink(email, window.location.href)
        .then((result) => {
          // Clear email from storage.
          eraseCookie("email");
          eraseCookie("isEmailVerified");
          setIsEmailVerified(true);
          //   window.localStorage.removeItem("emailForSignIn");
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
        })
        .catch((error) => {
          console.log(error);
          alert("Error");
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  const markEmailVerified = () => {};
  return (
    <div className="verify-email-wrapper">
      {isLoading ? (
        <div className="loader">
          <Spin />
        </div>
      ) : isEmailVerified ? (
        <center>
          <br />
          <br />
          <br />
          <br />
          <Result
            status="success"
            title="Successfully Verified Email!"
            extra={[
              <Button
                type="primary"
                key="console"
                onClick={() => {
                  history.push("/homepage");
                }}
              >
                Go to Dashboard
              </Button>,
            ]}
          />
        </center>
      ) : (
        <center>
          <br />
          <br />
          <br />
          <br />
          <Result
            title="Error! Try again later"
            extra={[
              <Button
                type="primary"
                key="console"
                onClick={() => {
                  history.push("/homepage");
                }}
              >
                Go to Dashboard
              </Button>,
            ]}
          />
        </center>
      )}
    </div>
  );
}

export default VerifyEmail;
