// "draft","ongoing", "published",  "discarded", "onhold",

export const statusColorCode = (status) => {
  switch (status) {
    case "draft":
      return "#a4b0be";

    case "ongoing":
      return "#3742fa";

    case "published":
      return "#2ed573";

    case "discarded":
      return "#ff4757";

    case "onhold":
      return "#eccc68";

    default:
      return "#82589F";
  }
};

export default statusColorCode;
