import API from "../../functions/axios";
const axios = require("axios");

export function getUploadLink(fileExtension) {
  return API.get("get-document-upload-link?extension=" + fileExtension);
}
export function getBooksNames() {
  return API.get("get-books-names");
}

export function uploadToS3(link, file) {
  const { type } = file;

  const config = {
    method: "put",
    url: link,
    headers: {
      "Content-Type": type,
    },
    data: file,
  };
  axios(config);
}

export function saveDocument(data) {
  return API.post("save-user-document", {
    ...data,
  });
}
