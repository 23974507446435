import React, { useEffect, useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";

import { Route, Switch, useRouteMatch } from "react-router-dom";
import { getCookie, eraseCookie } from "../../functions/cookie";
import { Alert, Button, message, Drawer } from "antd";
// style import
import "./homepage.scss";

import { MenuOutlined } from "@ant-design/icons";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

//components import
import Sidebar from "../../pageComponents/menu/menu";
import ProfilePage from "../../pageComponents/profile/profile";
import StatsPage from "../../pageComponents/stats/stats";
import ManagerPage from "../../pageComponents/manager/manager";
import SupportPage from "../../pageComponents/support/support";
import OneBookPage from "../../pageComponents/onebook/onebook";
import BooksPage from "../../pageComponents/books/books";
import AddBook from "../../pageComponents/books/createBook";
import UploadPage from "../../pageComponents/upload/upload";
import DocumentsPage from "../../pageComponents/documents/documents";
import { firebaseConfig } from "../../functions/configs";

function Homepage() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  let match = useRouteMatch();

  useEffect(() => {
    !firebase.apps.length
      ? firebase.initializeApp(firebaseConfig)
      : firebase.app();
  }, []);

  const toggleSidebar = () => {
    setIsMenuOpen((oldMenuState) => !oldMenuState);
  };

  const closeSidebar = () => {
    setIsMenuOpen(false);
  };

  return (
    <div className="homepage-wrapper">
      {!isMobile ? (
        <div className="homepage-sidebar">
          <Sidebar closeSidebar={closeSidebar} />
        </div>
      ) : (
        <>
          <Button
            onClick={toggleSidebar}
            className="mobile-drawer-toggler"
            type="primary"
            shape="circle"
            size="large"
            icon={<MenuOutlined />}
          />

          <Drawer
            placement="left"
            closable={true}
            onClose={closeSidebar}
            visible={isMenuOpen}
            closeIcon={null}
            // getContainer={false}
            // style={{ position: "absolute" }}
          >
            <Sidebar closeSidebar={closeSidebar} />
          </Drawer>
        </>
      )}

      <div className="homepage-main">
        <div className="homepage-main-content">
          {/* {isEmailVerificationSent ? (
            <Alert
              message={`Email Sent, please check your email`}
              type="success"
            />
          ) : null}
          {!isEmailVerified ? (
            <Alert
              message={`Please verify your email address : ${getCookie(
                "email"
              )}`}
              type="warning"
              action={
                <Button
                  size="small"
                  onClick={sendVerificationEmail}
                  type="primary"
                >
                  Click to send Verification Email
                </Button>
              }
            />
          ) : null} */}

          <Switch>
            <Route path={`${match.path}book/:id`} exact>
              <OneBookPage />
            </Route>
            <Route path={`${match.path}books`} exact>
              <BooksPage />
            </Route>
            <Route path={`${match.path}add-book`} exact>
              <AddBook />
            </Route>
            <Route path={`${match.path}profile`} exact>
              <ProfilePage />
            </Route>
            <Route path={`${match.path}documents`} exact>
              <DocumentsPage />
            </Route>
            <Route path={`${match.path}upload`} exact>
              <UploadPage />
            </Route>
            <Route path={`${match.path}manager`} exact>
              <ManagerPage />
            </Route>
            {/* <Route path={`${match.path}support`} exact>
              <SupportPage />
            </Route> */}
            <Route path={[`${match.path}`, `${match.path}homepage`]} exact>
              <BooksPage />
            </Route>
          </Switch>
          {/* <Route
          path="/login"
          exact
          render={(routerProps) => (
            <Sample {...routerProps} sampleProp={"sample"} />
          )}
        />
        <Route path="/something" component={Something} />
        <Route path="/default" render={() => <Redirect to="/" />} />
        <Route component={NoMatch} /> */}

          {/* <Stats /> */}
        </div>
      </div>
    </div>
  );
}

export default Homepage;
