import React, { useState, useEffect } from "react";

import { useHistory, useRouteMatch, Link } from "react-router-dom";
import {
  Spin,
  Card,
  Space,
  PageHeader,
  Result,
  Typography,
  Carousel,
  Button,
  Tag,
  Descriptions,
} from "antd";

import {
  PieChartOutlined,
  DesktopOutlined,
  ContainerOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import "./books.scss";

import { statusColorCode } from "../../functions/color-codes";

//asset imports
import logo from "../../asset/images/logo.svg";

// function imports
import { eraseCookie } from "../../functions/cookie";
import { getBooksList } from "./booksCalls";

// destructors
const { Meta } = Card;

function Books() {
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [booksData, setBooksData] = useState({});
  let history = useHistory();
  let match = useRouteMatch();

  useEffect(() => {
    async function fetchBooksList() {
      try {
        setLoading(true);
        setError(null);
        const response = await getBooksList();
        const { data, error, message } = response;
        if (!error) {
          setBooksData(data);
        } else {
          setError(message || "Error while fetching data");
        }
      } catch (error) {
        console.error(error);
        setError("sorry, something went wrong, please try again.");
      } finally {
        setLoading(false);
      }
    }
    fetchBooksList();
  }, []);

  const renderBooksList = (booksData) => {
    return booksData.map((bookData) => {
      const { images, title, subTitle, id, status } = bookData;

      const frontCover = images[0];
      return (
        <Link to={`/book/${id}`} className="one-book-in-list">
          <Card
            hoverable
            style={{ width: 240 }}
            cover={
              frontCover ? (
                <img
                  src={`https://ik.imagekit.io/wisdomwords/tr:n-bookmedsize/${images[0]}`}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "https://ik.imagekit.io/wisdomwords/tr:n-bookmedsize/cover_image_not_available_yet.jpg";
                  }}
                  alt=""
                />
              ) : (
                <img
                  src={`https://ik.imagekit.io/wisdomwords/tr:n-bookmedsize/cover_image_not_available_yet.jpg`}
                  alt=""
                />
              )
            }
          >
            <Meta
              title={title}
              description={
                <Space direction="vertical">
                  <div>{subTitle}</div>
                  <Tag color={statusColorCode(status)}>{status}</Tag>
                </Space>
              }
            />
          </Card>
        </Link>
      );
    });
  };

  return (
    <div className="books-wrapper">
      {isLoading ? (
        <div className="loader">
          <Spin />
        </div>
      ) : error !== null ? (
        <Result
          status="error"
          title={error}
          subTitle="please try again later."
        />
      ) : Object.keys(booksData).length === 0 &&
        booksData.constructor === Object ? (
        <Result
          status="404"
          title="404"
          subTitle="No data available, please contact support"
        />
      ) : (
        [
          <div className="header">
            {/* <PageHeader
              className="site-page-header"
              title={`Your Books`}
              subTitle={`You have ${booksData.length} ${
                booksData.length > 1 ? "books" : "book"
              }`}
            /> */}

            <PageHeader
              className="site-page-header"
              // onBack={() => window.history.back()}
              title="Books"
              subTitle={`You have ${booksData.length} ${
                booksData.length > 1 ? "books" : "book"
              }`}
              extra={[
                // <Button key="3">Operation</Button>,
                // <Button key="2">Operation</Button>,
                <Button key="1" type="primary">
                  <Link to="/add-book">Add New Book</Link>
                </Button>,
              ]}
            />
          </div>,
          <div className="body">{renderBooksList(booksData)}</div>,
        ]
      )}
    </div>
  );
}

export default Books;
