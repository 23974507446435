import React, { useState, useEffect, useRef } from "react";
import OneBookSteps from "./onebooksteps";

import { useHistory, useRouteMatch, Link, useParams } from "react-router-dom";
import {
  Spin,
  Card,
  PageHeader,
  Result,
  Typography,
  Carousel,
  Button,
  Icon,
  Collapse,
  Steps,
  Divider,
} from "antd";

import {
  PieChartOutlined,
  DesktopOutlined,
  ContainerOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import "./onebook.scss";

//asset imports
import logo from "../../asset/images/logo.svg";

// function imports
import { eraseCookie } from "../../functions/cookie";
import { getOneBook } from "./onebookCalls";
const { Title } = Typography;
const { Panel } = Collapse;
const { Step } = Steps;

// destructors
// const { SubMenu } = Menu;

function OneBook() {
  const { id: bookId = null } = useParams();
  const carouselRef = useRef();
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [bookData, setBookData] = useState({});
  let history = useHistory();
  let match = useRouteMatch();
  console.log(bookId, "lol");
  useEffect(() => {
    async function fetchBooksList() {
      try {
        setLoading(true);
        setError(null);
        const response = await getOneBook(bookId);
        const { data, error, message } = response;
        if (!error) {
          setBookData(data);
        } else {
          setError(message || "Error while fetching data");
        }
      } catch (error) {
        console.error(error);
        setError("sorry, something went wrong, please try again.");
      } finally {
        setLoading(false);
      }
    }
    fetchBooksList();
  }, []);

  const metaNameString = {
    title: "Title",
    binding: "Binding",
    edition: "Edition",
    dimension: "Dimension (inch)",
    languages: "Language",
    subTitle: "Sub-Title",
    isbn13: "ISBN",
    authors: "Author",
    aboutAuthor: "About the Author",
    aboutBook: "About the Book",
    pageCount: "Page count",
    publishingDate: "Publishing date",
    storeLinks: "Store links",
  };

  const renderMetaData = () => {
    const {
      title = null,
      subTitle = null,
      languages = [],
      isbn13 = null,
      storeLinks: { amazon = null, flipkart = null, snapdeal = null } = {
        amazon: null,
        flipkart: null,
        snapdeal: null,
      },
      edition,
      authors = [],
      aboutAuthor = null,
      aboutBook = null,
      images = [],
      binding = null,
      pageCount = null,
      weight = null,
      preface = null,
      dimension = null,
      publishingDate = null,
    } = bookData;

    const metaToRender = {
      title,
      subTitle,
      authors,
      preface,
      languages,
      isbn13,
      edition,
      binding,
      pageCount,
      weight,
      dimension,
      publishingDate: publishingDate
        ? new Date(publishingDate).toLocaleDateString()
        : "",
      storeLinks: {
        amazon,
        flipkart,
        snapdeal,
      },
      aboutAuthor,
      aboutBook,
    };
    return Object.keys(metaToRender).map(function (key, index) {
      const oneData = metaToRender[key];

      if (oneData !== null) {
        if (Array.isArray(oneData)) {
          return (
            <div className="meta-data">
              <div className="meta-name">
                {key in metaNameString ? metaNameString[key] : key}
              </div>
              <div className="meta-value">{oneData.join(", ")}</div>
            </div>
          );
        } else if (typeof oneData === "object" && key === "storeLinks") {
          return (
            <div className="meta-data">
              <div className="meta-name">
                {key in metaNameString ? metaNameString[key] : key}
              </div>
              <div className="meta-value store-links">
                {Object.keys(oneData).map(function (storeKey, storeIndex) {
                  if (oneData[storeKey] !== null) {
                    return (
                      <a
                        href={oneData[storeKey]}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {storeKey}
                      </a>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
          );
        } else {
          return (
            <div className={`meta-data ${key}`}>
              <div className={`meta-name`}>
                {key in metaNameString ? metaNameString[key] : key}
              </div>
              <div className={`meta-value ${key}`}>{oneData}</div>
            </div>
          );
        }
      } else {
        return null;
      }
    });
  };

  // const operationalTasksArr = JSON.parse(bookData.operationalTasks);
  // const coverDesignTasksArr = JSON.parse(bookData.coverDesignTasks);
  // const interiorDesignTasksArr = JSON.parse(bookData.interiorDesignTasks);
  // const marketingTasksArr = JSON.parse(bookData.marketingTasks);

  return (
    <div className="one-book-wrapper">
      {isLoading ? (
        <div className="loader">
          <Spin />
        </div>
      ) : error !== null ? (
        <Result
          status="error"
          title={error}
          subTitle="please try again later."
        />
      ) : Object.keys(bookData).length === 0 &&
        bookData.constructor === Object ? (
        <Result
          status="404"
          title="404"
          subTitle="No data available, please contact support"
        />
      ) : (
        [
          <div className="header">
            <PageHeader
              className="site-page-header"
              title={
                <Link to={`/books`}>
                  <Button type="primary">
                    <LeftOutlined />
                    View all books
                  </Button>
                </Link>
              }
              subTitle={bookData.title}
            />
          </div>,
          <div className="meta-body">
            <div className="left-panel">
              <div className="status">
                <p>Status : {bookData.status}</p>
              </div>
              <div className="images">
                <div
                  className="arrow left"
                  onClick={() =>
                    carouselRef.current && carouselRef.current.prev()
                  }
                >
                  <LeftOutlined />
                </div>
                {bookData.images.length !== 0 ? (
                  <Carousel ref={carouselRef} dots={false}>
                    {bookData.images.map((image) => {
                      return (
                        <div className="one-image">
                          <img src={image} alt="" />
                        </div>
                      );
                    })}
                  </Carousel>
                ) : (
                  <div className="no-book-image">
                    <p>No cover image yet</p>
                  </div>
                )}
                <div
                  className="arrow right"
                  onClick={() =>
                    carouselRef.current && carouselRef.current.next()
                  }
                >
                  <RightOutlined />
                </div>
              </div>
              {bookData.ordersReport ? (
                <center>
                  <Divider />
                  <h4>Sales Report</h4>
                  <a href={bookData.ordersReport} target="_blank">
                    <Button type="primary">Open file</Button>
                  </a>
                </center>
              ) : null}
            </div>
            <div className="right-panel">
              <center className="help">
                Information correction & addition of new data will be undertaken
                by your publishing manager.
              </center>
              <div className="meta-data-wrapper">{renderMetaData()}</div>
            </div>
            {/* <section className="publishing-steps">
              <h2>Pre Publishing Tasks</h2>
              <p>
                These tasks are related to pre publishing of book, and may not
                necessarily be in order.
              </p>
              <div className="steps">
                {bookData.prePublishingTasks.length === 0 ? (
                  <div className="no-steps">
                    <h4>no tasks planned yet!</h4>
                    <p>your publishing manager will add tasks in future</p>
                  </div>
                ) : null}
                {bookData.prePublishingTasks.map((steps) => {
                  return (
                    <div className={`one-step ${steps.status}`}>
                      <div className="name">{steps.processName}</div>
                      <div className="status">{steps.status}</div>
                    </div>
                  );
                })}
              </div>
            </section>

            <Divider />
            <section className="publishing-steps">
              <h2>Post Publishing Tasks</h2>
              <p>
                These tasks are related to post publishing of book, and may not
                necessarily be in order.
              </p>
              <div className="steps">
                {bookData.postPublishingTasks.length === 0 ? (
                  <div className="no-steps">
                    <h4>no tasks planned yet!</h4>
                    <p>your publishing manager will add tasks in future</p>
                  </div>
                ) : null}
                {bookData.postPublishingTasks.map((steps) => {
                  return (
                    <div className={`one-step ${steps.status}`}>
                      <div className="name">{steps.processName}</div>
                      <div className="status">{steps.status}</div>
                    </div>
                  );
                })}
              </div>
            </section> */}
            <Divider />
            <section className="publishing-steps">
              <h2>Operational Tasks</h2>
              <div className="steps">
                {JSON.parse(bookData.operationalTasks).length === 0 ? (
                  <div className="no-steps">
                    <h4>no tasks planned yet!</h4>
                    <p>your publishing manager will add tasks in future</p>
                  </div>
                ) : null}
                {JSON.parse(bookData.operationalTasks).map((steps) => {
                  return (
                    <div className={`one-step ${steps.status}`}>
                      <div className="name">{steps.task}</div>
                      <div className="status">{steps.status}</div>
                    </div>
                  );
                })}
              </div>
            </section>
            <Divider />
            <section className="publishing-steps">
              <h2>Cover Design Tasks</h2>
              <div className="steps">
                {JSON.parse(bookData.coverDesignTasks).length === 0 ? (
                  <div className="no-steps">
                    <h4>no tasks planned yet!</h4>
                    <p>your publishing manager will add tasks in future</p>
                  </div>
                ) : null}
                {JSON.parse(bookData.coverDesignTasks).map((steps) => {
                  return (
                    <div className={`one-step ${steps.status}`}>
                      <div className="name">{steps.task}</div>
                      <div className="status">{steps.status}</div>
                    </div>
                  );
                })}
              </div>
            </section>
            <Divider />
            <section className="publishing-steps">
              <h2>Interior Design Tasks</h2>
              <div className="steps">
                {JSON.parse(bookData.interiorDesignTasks).length === 0 ? (
                  <div className="no-steps">
                    <h4>no tasks planned yet!</h4>
                    <p>your publishing manager will add tasks in future</p>
                  </div>
                ) : null}
                {JSON.parse(bookData.interiorDesignTasks).map((steps) => {
                  return (
                    <div className={`one-step ${steps.status}`}>
                      <div className="name">{steps.task}</div>
                      <div className="status">{steps.status}</div>
                    </div>
                  );
                })}
              </div>
            </section>
            <Divider />
            <section className="publishing-steps">
              <h2>Marketing Tasks</h2>
              <div className="steps">
                {JSON.parse(bookData.marketingTasks).length === 0 ? (
                  <div className="no-steps">
                    <h4>no tasks planned yet!</h4>
                    <p>your publishing manager will add tasks in future</p>
                  </div>
                ) : null}
                {JSON.parse(bookData.marketingTasks).map((steps) => {
                  return (
                    <div className={`one-step ${steps.status}`}>
                      <div className="name">{steps.task}</div>
                      <div className="status">{steps.status}</div>
                    </div>
                  );
                })}
              </div>
            </section>

            <Divider />
          </div>,
        ]
      )}
    </div>
  );
}

export default OneBook;
