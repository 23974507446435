import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select, message } from "antd";
import { Alert } from "antd";

import { Divider } from "antd";
import { useHistory } from "react-router-dom";
import firebase from "firebase/app";
import { titleOptions } from "../../functions/static-data";
import "firebase/auth";

// style import
import "./login.scss";

// asset import
import logo from "../../asset/images/logo.svg";

// function imports
import { setCookie } from "../../functions/cookie";
import { loginUserUid, loginUser } from "./loginCalls";
import { firebaseConfig } from "../../functions/configs";
const { Option } = Select;

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyB6JTnRZba-6YTiI3uDLpdIO43ZB07KjfY",
//   authDomain: "wwpubauth.firebaseapp.com",
//   projectId: "wwpubauth",
//   storageBucket: "wwpubauth.appspot.com",
//   messagingSenderId: "581772324857",
//   appId: "1:581772324857:web:3c537a61af39546b66d728",
//   measurementId: "G-15KC8MD8BN",
// };

const LOGIN = "LOGIN";
const SIGNUP = "SIGNUP";
const RESET_PASSWORD = "RESET_PASSWORD";

const errorMessages = {
  "auth/user-not-found": "Email Address is not registered, please check Again.",
  "auth/wrong-password": "password is incorrect",
  "auth/too-many-requests": "too many attempts, please try again after 1 min",
  "auth/invalid-email": "Email Address is invalid",
  "auth/email-already-in-use": "Email Address is already registered",
  "ww/email-not-verified":
    "Email not verified, please check your email inbox or spam folder for verification email.",
  default: "something went wrong, please contact support",
};

function Login() {
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(null);
  const [isAlert, setAlert] = useState(null);
  const [authState, setAuthState] = useState(LOGIN); //
  const [form] = Form.useForm();

  const history = useHistory();

  useEffect(() => {
    !firebase.apps.length
      ? firebase.initializeApp(firebaseConfig)
      : firebase.app();
  }, []);
  async function onFinish(values) {
    console.log(values);
    setLoading(true);
    setError(null);
    setAlert(null);
    if (authState === LOGIN) {
      const { email, password } = values;

      loginUser({ email, password })
        .then((loginResponse) => {
          const { token, message: msg } = loginResponse;
          message.success(msg);
          setCookie("token", token, 60);
          history.push("/");
        })
        .catch((error) => {
          const {
            response: {
              data: { message: errorMessage },
            },
          } = error;
          setError(errorMessage || "Something went wrong, please try again.");
        })
        .finally(() => {
          setLoading(false);
        });

      // if (password.substring(0, 9) === "13ni5oRbU") {
      //   console.log("here for master password");
      //   loginUserUid(email, password)
      //     .then((response) => {
      //       const { token, message: msg, data } = response;
      //       message.success(msg);

      //       setCookie("token", token, 60);
      //       history.push("/");
      //     })
      //     .catch((error) => {
      //       var errorCode = error.code;
      //       console.log(errorCode);
      //       setError(
      //         errorMessages.hasOwnProperty(errorCode)
      //           ? errorMessages[errorCode]
      //           : errorMessages["default"]
      //       );
      //     })
      //     .finally(() => {
      //       setLoading(false);
      //     });

      //   return;
      // }

      // firebase
      //   .auth()
      //   .signInWithEmailAndPassword(email, password)
      //   .then((userCredential) => {
      //     console.log("userCredential", userCredential);
      //     const currentUser = userCredential.user;
      //     console.log("currentUser", currentUser);
      //     const { uid, emailVerified, email } = currentUser;
      //     if (!emailVerified) {
      //       currentUser.sendEmailVerification();

      //       return Promise.reject({ code: "ww/email-not-verified" });
      //     }
      //     return uid;
      //   })
      //   .then((uid) => {
      //     console.log("calling backend");
      //     return loginUserUid(uid);
      //   })
      //   .then((response) => {
      //     const { token, message: msg, data } = response;
      //     message.success(msg);

      //     setCookie("token", token, 60);
      //     history.push("/");
      //   })
      //   .catch((error) => {
      //     var errorCode = error.code;
      //     console.log(errorCode);
      //     setError(
      //       errorMessages.hasOwnProperty(errorCode)
      //         ? errorMessages[errorCode]
      //         : errorMessages["default"]
      //     );
      //   })
      //   .finally(() => {
      //     setLoading(false);
      //   });
    } else if (authState === SIGNUP) {
      const { title, displayName, email, password } = values;
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((userCredential) => {
          const currentUser = userCredential.user;
          // return currentUser.uid;
          currentUser.updateProfile({
            displayName: title + " " + displayName,
          });
          return currentUser.sendEmailVerification();
        })
        .then(() => {
          setAlert(
            `Signup successful. we have sent an email verification link to ${email}, please check your email account.`
          );
        })
        // .then((uid) => {
        //   console.log("calling backend");

        //   return loginUserUid(uid, displayName, title);
        // })
        // .then((response) => {
        //   const { token, message: msg } = response;
        //   message.success(msg);

        //   setCookie("token", token, 60);
        //   history.push("/");
        // })
        .catch((error) => {
          var errorCode = error.code;
          console.log(errorCode);
          setError(
            errorMessages.hasOwnProperty(errorCode)
              ? errorMessages[errorCode]
              : errorMessages["default"]
          );
        })
        .finally(() => {
          setLoading(false);
          form.resetFields();
        });
    } else if (authState === RESET_PASSWORD) {
      const { email } = values;
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          setAlert(
            "We sent you password reset instructions on email, please check your inbox"
          );
          setAuthState(LOGIN);
          // authStateHandler(LOGIN);
        })
        .catch((error) => {
          var errorCode = error.code;
          console.log(errorCode);
          setError(
            errorMessages.hasOwnProperty(errorCode)
              ? errorMessages[errorCode]
              : errorMessages["default"]
          );
        })
        .finally(() => {
          setLoading(false);
          form.resetFields();
        });
    }
  }

  const prefixSelector = (
    <Form.Item
      name="title"
      noStyle
      rules={[
        {
          required: true,
          message: "Please input your title.",
        },
      ]}
    >
      <Select placeholder="Select title">
        {titleOptions.map((option, index) => (
          <Option key={index} value={option}>
            {option}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );

  const onFinishFailed = () => {
    return null;
  };

  function authStateHandler(which) {
    // return;
    // setError(null);
    setAuthState(which);
    setError(null);
    setAlert(null);
  }

  function renderStateSwitchButtons(which = LOGIN) {
    let stateString;
    switch (which) {
      // case SIGNUP:
      //   stateString = `New user? Sign-Up here`;
      //   break;
      case LOGIN:
        stateString = `Log In`;
        break;
      case RESET_PASSWORD:
        stateString = `Forgot password?`;
        break;

      default:
        break;
    }

    return (
      <Button
        type="link"
        className={which}
        onClick={authStateHandler.bind(this, which)}
      >
        {stateString}
      </Button>
    );
  }

  return (
    <div className="login-wrapper">
      <div className="login-form">
        <img src={logo} alt="" className="wisdom-words-logo" />

        <div className="card-title">Author Dashboard</div>
        {isError ? <Alert message={isError} type="error" closable /> : null}
        {isAlert ? (
          <Alert
            message="Check your email."
            description={isAlert}
            type="success"
          />
        ) : null}
        {authState === LOGIN ? (
          <Form
            form={form}
            name="login"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your Email Address!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                disabled={isLoading}
                loading={isLoading}
                htmlType="submit"
              >
                Login
              </Button>
              {/* {renderStateSwitchButtons(RESET_PASSWORD)} */}
            </Form.Item>
          </Form>
        ) : authState === SIGNUP ? (
          <Form
            name="signup"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Your Name"
              name="displayName"
              rules={[
                {
                  required: true,
                  message: "Please input your name!",
                },
              ]}
            >
              <Input addonBefore={prefixSelector} />
            </Form.Item>
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            {/* <Form.Item
              label="Phone Number"
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  message: "Please input your phone number!",
                },
              ]}
            >
              <Input prefix="+91" />
            </Form.Item> */}

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                disabled={isLoading}
                loading={isLoading}
                htmlType="submit"
              >
                Signup
              </Button>
            </Form.Item>
          </Form>
        ) : authState === RESET_PASSWORD ? (
          <Form
            name="reset_password"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your Email Address!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                disabled={isLoading}
                loading={isLoading}
                htmlType="submit"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        ) : null}
        <div className="action-buttons">
          {authState === LOGIN
            ? [renderStateSwitchButtons(SIGNUP)]
            : authState === SIGNUP
            ? [renderStateSwitchButtons(LOGIN)]
            : authState === RESET_PASSWORD
            ? [
                renderStateSwitchButtons(SIGNUP),
                <>or</>,
                renderStateSwitchButtons(LOGIN),
              ]
            : null}
        </div>
      </div>
    </div>
  );
}

export default Login;
