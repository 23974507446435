import API from "../../functions/axios";

export function loginUser({ email, password }) {
  return API.post(
    "user/login",
    {
      email,
      password,
    },
    {
      headers: {
        "cache-control": "no-cache",
        "Access-Control-Allow-Origin": "*",
      },
    }
  );
}
export function loginUserUid(uid, displayName = undefined, title) {
  return API.post(
    "user/auth",
    {
      uid,
      displayName,
      title,
    },
    {
      headers: {
        "cache-control": "no-cache",
        "Access-Control-Allow-Origin": "*",
      },
    }
  );
}
