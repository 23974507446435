/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useHistory, useRouteMatch, Link } from "react-router-dom";
import {
  Spin,
  Card,
  PageHeader,
  Result,
  Typography,
  Carousel,
  Button,
  Upload,
  Radio,
  Divider,
  message as antdMessage,
  Badge,
  Input,
  Form,
  Checkbox,
  Alert,
} from "antd";

import {
  PieChartOutlined,
  DesktopOutlined,
  ContainerOutlined,
  UploadOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import "./upload.scss";

//asset imports
import logo from "../../asset/images/logo.svg";

// function imports
import { eraseCookie } from "../../functions/cookie";
import {
  getUploadLink,
  uploadToS3,
  saveDocument,
  getBooksNames,
} from "./uploadCalls";

// destructors
// const { SubMenu } = Menu;
const { Title, Text } = Typography;
const { TextArea } = Input;

function UploadPage() {
  const [isLoading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [booksData, setBooksData] = useState([]);
  const [uploadedData, setUploadedData] = useState(null);
  const [error, setError] = useState(null);
  const [file, setFIle] = useState(undefined);
  const [form] = Form.useForm();

  const beforeUpload = (file) => {
    setFIle(file);

    return false;
  };

  const onRemove = () => {
    setFIle(undefined);
  };

  const fileList = [file].filter(Boolean);

  useEffect(() => {
    async function fetchDocuments() {
      try {
        const response = await getBooksNames();
        const { data, error, message } = response;
        if (!error) {
          setBooksData(data);
        } else {
          setError(message || "Error while fetching data");
        }
      } catch (error) {
        console.error(error);
        setError("sorry, something went wrong, please try again.");
      } finally {
        setLoading(false);
      }
    }
    fetchDocuments();
  }, []);

  const onFinish = async (values) => {
    console.log("values", values);
    // return;
    try {
      setUploading(true);
      setUploadedData(null);
      const { title, comment, book, type } = values;
      setError(null);
      const fileExtension = file.name.split(".").pop();
      console.log(fileExtension, "fileex");

      const uploadInfo = await getUploadLink(fileExtension);
      const { fileName, url } = uploadInfo.data;
      const uploadedFileResponse = await uploadToS3(url, file);
      const savedForm = await saveDocument({
        title,
        comment,
        url: fileName,
        book,
        type,
      });
      const { error, message } = savedForm;
      if (!error) {
        console.log(uploadInfo);
        antdMessage.success("Successfully uploaded file");
        form.resetFields();
        setFIle(undefined);
        setUploadedData({ title, comment, type, url: fileName });
      } else {
        antdMessage.error("unable to upload file, please try again");
        setError(message || "Error while fetching data");
      }
    } catch (error) {
      console.error(error);
      setError("sorry, something went wrong, please try again.");
    } finally {
      setUploading(false);
    }
  };

  if (uploadedData) {
    // console.log("uploadedDatauploadedData", uploadedData);

    const {
      title = null,
      comment = null,
      type = null,
      url = null,
    } = uploadedData;
    return (
      <center className="box uploaded">
        <br />
        <br />
        <Alert
          message="File uploaded successfully"
          description="Your publishing manager has been notified about this new file"
          type="success"
        />
        <br />
        {title ? <Text>File title : {title}</Text> : null}
        <br />
        {title ? <Text>File type : {type}</Text> : null}
        <br />
        {comment ? <Text type="secondary">Description : {comment}</Text> : null}
        <br />
        {url ? (
          <a href={url} target="_blank">
            <Button type="link">view uploaded document</Button>
          </a>
        ) : null}
        <Divider>or</Divider>
        <Link to="/documents">
          <Button>View all files</Button>
        </Link>
        <Divider>or</Divider>
        <Button
          type="primary"
          onClick={() => {
            setUploadedData();
          }}
        >
          Upload new file
        </Button>
        <br />
      </center>
    );
  }

  if (isLoading) {
    return (
      <div className="loader">
        <Spin />
      </div>
    );
  }

  if (!booksData.length) {
    return (
      <center className="box no-book">
        <br />
        <Alert message="You don't have any books yet" type="error" />
        <br />
        <Text>Create a book to upload documents</Text>
        <br />
        <br />
        <Link to={"/add-book"}>
          <Button type="primary">Create new book</Button>
        </Link>
      </center>
    );
  }

  return (
    <div className="upload-wrapper">
      <Title>Upload a file</Title>
      <Text>
        Upload any file related to your book such as manuscript, image and
        agreement etc.{" "}
      </Text>
      <br />
      or
      <Link to={"/add-book"}>
        <Button type="primary" size="small">
          Create new book
        </Button>
      </Link>
      <Divider />
      <br />
      <br />
      <fieldset>
        <Form
          // {...layout}
          form={form}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="book"
            label="Document for (Select any one book)"
            rules={[
              {
                required: true,
                message: "required!",
              },
            ]}
          >
            <Radio.Group className="r">
              {booksData.map((book) => {
                const { id, title } = book;
                return (
                  <Radio value={id} key={id}>
                    {title}
                  </Radio>
                );
              })}
            </Radio.Group>
          </Form.Item>

          <Divider />

          <Form.Item
            name="type"
            label="Document type"
            rules={[
              {
                required: true,
                message: "required!",
              },
            ]}
          >
            <Radio.Group>
              <Radio value="agreement">Signed Agreement</Radio>
              <Radio value="manuscript">Manuscript</Radio>
              <Radio value="other">Other</Radio>
            </Radio.Group>
          </Form.Item>
          <Divider />

          <Form.Item
            label="Select a file"
            name="file"
            placeholder=""
            rules={[
              {
                required: true,
                message: "required!",
              },
            ]}
          >
            <Upload
              fileList={fileList}
              multiple={false}
              maxCount={1}
              beforeUpload={beforeUpload}
              onRemove={onRemove}
            >
              <Button type="primary" icon={<UploadOutlined />}>
                Select file
              </Button>
            </Upload>
          </Form.Item>

          <Form.Item
            label="File Title"
            name="title"
            placeholder="e.g. Signed Agreement for BookName"
            rules={[
              {
                required: true,
                message: "required!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Description (optional)"
            name="comment"
            placeholder=""
          >
            <TextArea />
          </Form.Item>

          <Form.Item>
            <center>
              <Button
                size="large"
                loading={uploading}
                disabled={uploading}
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
            </center>
          </Form.Item>
        </Form>
      </fieldset>
    </div>
  );
}

export default UploadPage;
